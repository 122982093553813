<template>
    <div class="box my-account">
        <div class="member-head">
            <!-- <el-button @click="openLog">查看日志</el-button> -->
            <el-button type="primary" @click="addData">添加</el-button>
            <el-button v-if="tableData.length>0?true:false" @click="openNewData()">批量导出</el-button>
            <div class="member-head-load">
                <el-upload
                    class="upload-demo"
                    :action="uploadExclUrl"
                    :on-preview="handlePreview"
                    :on-remove="handleRemove"
                    :before-remove="beforeRemove"
                    accept=".xls"
                    :show-file-list="false"
                    :on-exceed="handleExceed"
                    :on-success="exclInsertSuccess"
                    :file-list="fileList">
                    <el-button>批量导入</el-button>
                </el-upload>
            </div>
            <el-button @click="fileLoad" type="primary">模板下载</el-button>
        </div>
        <div class="member-table">
            <el-table :data="tableData">
                <el-table-column prop="realname" label="姓名"></el-table-column>
                <el-table-column prop="mobile" label="手机号"></el-table-column>
                <el-table-column prop="date" label="操作">
                    <template slot-scope="scope">
                        <el-button class="layui-btn layui-btn-xs" @click="delData(scope.row)" v-if="scope.row.mobile != member.mobile">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div class="member-page">
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page.sync="page.page"
                :page-size="page.pageSize"
                layout="total, prev, pager, next"
                :total="pageTotal">
            </el-pagination>
        </div>
        <el-dialog
            :title="dialogTitle"
            :visible.sync="dialogVisible"
            width="50%">
            <div class="dialogData">
                <div class="logData" v-if="dialogDataIndex === 1" :key="dialogDataIndex">
                    <el-card class="logData_list" v-for="o in 4" :key="o" >
                        <div slot="header" class="logData_conter">
                            <span>2021/02/14 14：14：14</span>
                            <span>导入失败</span>
                        </div>
                        <div v-for="o in 4" :key="o" class="text item">
                            {{'列表内容 ' + o }}
                        </div>
                    </el-card>
                </div>
                <div class="addData" v-if="dialogDataIndex === 2" :key="dialogDataIndex">
                    <el-form :model="addDatalist" label-width="80px" :rules="rules" ref="ruleForm">
                        <el-form-item label="真实姓名" prop="admin_name">
                            <el-input v-model="addDatalist.admin_name" placeholder="请输入姓名"></el-input>
                        </el-form-item>
                        <el-form-item label="手机号" prop="mobile">
                            <el-input v-model="addDatalist.mobile" placeholder="请输入手机号"></el-input>
                        </el-form-item>
                        <el-form-item label="密码" prop="password">
                            <el-input v-model="addDatalist.password" placeholder="请输入密码" show-password type="password"></el-input>
                        </el-form-item>
                        <el-form-item label="重复密码" prop="repwd">
                            <el-input v-model="addDatalist.repwd" placeholder="请输入密码" show-password type="password"></el-input>
                        </el-form-item>
                    </el-form>
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
              <el-button @click="dialogVisible = false">取 消</el-button>
              <el-button type="primary" @click="allfuntion">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
    import { memberList, memberAdd,memberKick,exportExcl,importMember_pc} from '@/api/member/index';
    import Utli from "@/utils/util"
    import Config from '@/utils/config';
    import { mapGetters } from "vuex"

    export default {
        name: "memberManagement",
        computed: {
            ...mapGetters(["member"]),
        },
        data() {
            var usernameValidata = (rule, value, callback) => {
                if (value === '') {
                    callback(new Error('请输入手机号！'));
                } else if (!Utli.checkPhone(value)) {
                    callback(new Error('请输入正确的手机号码!'));
                } else {
                    callback();
                }
            };
            var checkPassValidata = (rule, value, callback) => {
                if (value === '') {
                    callback(new Error('请再次输入密码'));
                } else if (value !== this.addDatalist.password) {
                    callback(new Error('两次输入密码不一致!'));
                } else {
                    callback();
                }
            };
            let self = this;
            var passwordValidata = function(rule, value, callback) {
                let regConfig = self.registerConfig;
                if (!value) {
                    return callback(new Error('请输入密码'));
                } else {
                    if (regConfig.pwd_len > 0) {
                        if (value.length < regConfig.pwd_len) {
                            return callback(new Error('密码长度不能小于' + regConfig.pwd_len + '位'));
                        } else {
                            callback();
                        }
                    } else {
                        if (regConfig.pwd_complexity != '') {
                            let passwordErrorMsg = '密码需包含',
                                reg = '';
                            if (regConfig.pwd_complexity.indexOf('number') != -1) {
                                reg += '(?=.*?[0-9])';
                                passwordErrorMsg += '数字';
                            } else if (regConfig.pwd_complexity.indexOf('letter') != -1) {
                                reg += '(?=.*?[a-z])';
                                passwordErrorMsg += '、小写字母';
                            } else if (regConfig.pwd_complexity.indexOf('upper_case') != -1) {
                                reg += '(?=.*?[A-Z])';
                                passwordErrorMsg += '、大写字母';
                            } else if (regConfig.pwd_complexity.indexOf('symbol') != -1) {
                                reg += '(?=.*?[#?!@$%^&*-])';
                                passwordErrorMsg += '、特殊字符';
                            } else {
                                reg += '';
                                passwordErrorMsg += '';
                            }

                            if (reg.test(value)) {
                                return callback(new Error(passwordErrorMsg));
                            } else {
                                callback();
                            }
                        }
                    }
                }
            };
            return {
                registerConfig: {
                    pwd_len: 6
                },
                dialogVisible: false,
                dialogDataIndex: 1,
                dialogTitle: '',
                addDatalist:{
                    admin_name: '',
                    password: '',
                    name: '',
                    mobile: '',
                },
                rules: {
                    admin_name: [
                        { required: true, message: '请输入真实姓名', trigger: 'blur' },
                        { min: 2, max: 16, message: '长度在 2 到 16 个字符', trigger: 'blur' }
                    ],
                    password: [
                        {
                            required: true,
                            validator: passwordValidata,
                            trigger: 'blur'
                        }
                    ],
                    repwd: [{ required: true, validator: checkPassValidata, trigger: 'blur' }],
                    mobile: [{ required: true, validator: usernameValidata,  trigger: 'blur' }],

                },
                fileList: [{
                    name: "food.jpeg",
                    url: "https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100"
                }, {
                    name: "food2.jpeg",
                    url: "https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100"
                }],
                tableData: [],
                pageTotal: 0,
                page: {
                    page: 1,
                    pageSize: 10
                },
                uploadExclUrl: Config.baseUrl + '/api/upload/uploadMemberExcl?type=4'//必带参数type=4 为文件上传
            }
        },
        mounted() {
            // 成员管理 列表
            this.memberListData()
        },
        methods: {
            delData(row) {
                    this.$confirm('此操作将永久删除该记录, 是否继续?', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        this.memberKickData(row.member_id)
                    }).catch(() => {
                        this.$message({
                            type: 'info',
                            message: '已取消删除'
                        });
                    });
            },
            // 成员管理 删除
            memberKickData(id) {
                let list = {
                    member_id: id
                }
                memberKick(list).then(res => {
                    this.page.page = 1
                    this.memberListData()
                    this.$message({
                        type: 'success',
                        message: '删除成功!'
                    });
                })
            },
            // 成员管理新增
            memberAddData() {
                let data = {
                    admin_name: this.addDatalist.admin_name,
                    password: this.addDatalist.password,
                    repwd: this.addDatalist.password,
                    mobile: this.addDatalist.mobile,
                }
                memberAdd(data).then(res => {
                    this.$message.success('提交成功！')
                    this.resetForm('ruleForm')
                    this.page.page = 1
                    this.memberListData()
                }).catch(e => {
                    this.$message.warning(e.message)
                })
            },
            // 成员管理 列表
            memberListData() {
                memberList(this.page).then(res => {
                   let data = res.data
                    this.tableData =  data.list
                    this.pageTotal =  data.count
                })
            },
            handleSizeChange(val) {
            },
            handleCurrentChange(val) {
                this.page.page = val
                this.memberListData()
            },
            // 查看日志
            openLog() {
                this.dialogVisible = !this.dialogVisible
                this.dialogTitle = '导入失败日志'
                this.dialogDataIndex = 1
            },
            // 公共处理确定按钮
            allfuntion() {
                if (this.dialogDataIndex === 1) {
                    this.dialogVisible = !this.dialogVisible
                } else if (this.dialogDataIndex === 2) {
                    this.addUser()
                }
            },
            addData() {
                this.dialogVisible = !this.dialogVisible
                this.dialogDataIndex = 2
                this.dialogTitle = '添加用户'
            },
            addUser() {
                this.$refs['ruleForm'].validate((valid) => {
                    if (valid) {
                        this.dialogVisible = !this.dialogVisible
                        this.memberAddData()
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            //导出成员
            openNewData(item) {
                let data = {};
                exportExcl(data).then(res => {
                    let data = res.data
                    if (data) {
                        window.open(data)
                    }else {
                        this.$message.error('无导出数据！')
                    }
                    console.log(res)
                })
            },
            // 重置表单
            resetForm(formName) {
                this.$refs[formName].resetFields();
            },
            //下载导入模板
            fileLoad() {
                 window.open(Config.baseUrl+'/fupin/api/organization/download', '_blank');
            },
            exclInsertSuccess(res, file) {

                if(res.data && res.data.path){
                    var loading = this.$loading({
                        text: "会员导入中...",
                        background: "rgba(0, 0, 0, 0.7)"
                    })
                    let data = {
                        'filename' : res.data.name,
                        'path' : res.data.path
                    };
                    importMember_pc(data).then(rel => {
                        loading.close()
                        if(rel.code==0){
                            this.$message.success(rel.message)
                            this.memberListData();
                        }else{
                            this.$message.error(rel.message)
                        }
                    }).catch(err=>{
                        loading.close()
                        this.$message.error(err.message)
                    })
                }
            },
            handleRemove(file, fileList) {
                console.log(file, fileList)
            },
            handlePreview(file) {
                console.log(file)
            },
            handleExceed(files, fileList) {
                this.$message.warning(`当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`)
            },
            beforeRemove(file, fileList) {
                return this.$confirm(`确定移除 ${file.name}？`)
            }
        }
    }
</script>

<style type="text/scss" lang="scss">
    .layui-btn-xs {
        height: 22px;
        line-height: 22px;
        padding: 0 5px;
        font-size: 12px;
        margin-bottom: 5px;
    }
</style>
<style scoped type="text/scss" lang="scss">
  .my-account {
    background: #ffffff;
    padding: 20px;
  }
    .logData{
      height: 400px;
      overflow: auto;
      &_conter{
        display: flex;
        justify-content: space-between;
      }
      &_list{
        margin-bottom: 10px;
      }
    }
    .addData{
      width: 500px;
    }
    .member-page{
      ::v-deep .el-pagination {
        margin-top: 10px;
        text-align: center;
      }
    }
  .member-head {
    text-align: right;

    &-load {
      display: inline-block;
      margin: 0 10px;
    }
  }

  .member-table {
    margin-top: 10px;
  }
</style>
